<template>
  <div class="modal fade" :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName"
       aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">
            {{ modalUsagesFor === 2 ? "Update Delivery Charge" : "Add discount" }}
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div v-if="modalUsagesFor === 1" class="col-12">
              <div class="form-group">
                <div class="controls">
                  <label>Applied Discount</label>
                  <div style="margin-top: .5rem">
                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                      <div>
                        <input v-model="appliedDiscount" type="checkbox" class="custom-control-input" checked
                               id="appliedDiscount">
                        <label class="custom-control-label mr-1" for="appliedDiscount"></label>
                      </div>
                      <span class="font-medium-1">{{ appliedDiscount ? "Yes" : "No" }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!appliedDiscount && modalUsagesFor === 1" class="col-12 col-md-5">
              <div class="form-group">
                <div class="controls">
                  <label>Name</label>
                  <input v-model.trim="name" type="tel" class="form-control "/>
                  <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="form-group">
                <div class="controls">
                  <label>Amount</label>
                  <input v-model.number="amount" type="number" class="form-control">
                  <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="addDiscount">
            <span class="">Save Changes</span>
          </button>
          <!-- data-dismiss="modal" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "UpdateDeliveryChargeModal",
  mixins: [ShowToastMessage],
  emits: ['getDiscount', 'getDeliveryCharge'],

  props: {
    modalName: {
      type: String,
      required: true,
      default: 'updateCharge'
    },
    modalUsagesFor: {
      type: Number,
      default: 1, // 1 for add applied discount, 2 for update delivery charge.
      required: true,
    },
    deliveryChargeAmount: {
      type: [Number,String],
      default: 0,
      required: false
    }
  },
  data() {
    return {
      appliedDiscount: true,
      amount: '',
      name: '',
      errors: {
        amount: '',
        name: ''
      }
    }
  },
  watch: {
    deliveryChargeAmount(chargeAmount) {
      this.amount = chargeAmount/100
    }
  },

  methods: {
    addDiscount() {
      if (this.modalUsagesFor === 1) {
        let name = this.appliedDiscount === true ? 'Applied Discount' : this.name
        let isNameNotNull = Boolean(name)
        let isAmountNull = this.amount === null || this.amount === undefined || this.amount === ''
        if (!isNameNotNull) {
          this.showToastMessage({type: 'error', message: 'Please put a name'})
          return
        }
        if (isAmountNull) {
          this.showToastMessage({type: 'error', message: 'Please put a valid amount'})
          return
        }
        const dataObj = {
          name: name,
          amount: this.amount
        }
        this.$emit('getDiscount', dataObj)
      }

      if (this.modalUsagesFor === 2) {
        let isAmountNull = this.amount === null || this.amount === undefined || this.amount === ''
        if (isAmountNull) {
          this.showToastMessage({type: 'error', message: 'Please put a valid amount'})
          return
        }
        this.$emit('getDeliveryCharge', this.amount)
      }

    }
  }

}
</script>

<style scoped>

</style>