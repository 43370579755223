<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="my-2 bg-white ">

        <div class="px-2">
          <!-- invoice view page -->
          <div class="">
            <div class="pb-0">
              <div class=" ">
                <div class="text-center text-sm-left order-2 order-sm-1 pt-4">

                  <table class="table table-borderless mb-0">

                    <tbody>
                    <tr v-if="orderDetails.order_no" class="mb-1">
                      <td class="py-0 pl-0 font-weight-light" style="width: 20%; color: #58595B">Ref :</td>
                      <td class="py-0 pl-0" style="width: 80%"><span class="font-weight-light" style="color: #58595B">#{{
                          orderDetails.order_no
                        }}</span>
                        <span v-if="orderDetails.appointment">
                          <span><i class="bx bx-link" style="font-size: 1.5rem;"></i> </span>
                          <span v-if="orderDetails.appointment.type == 'Home'">
                            <i class="bx bx-home" style="font-size: 1.5rem;"></i>
                          </span>
                            <span v-else><i class="bx bxs-business" style="font-size: 1.5rem;"></i>   </span>
                            <span class="text-secondary text-light">#{{ orderDetails.appointment.reference }}</span>
                          </span>
                      </td>
                    </tr>
                    <tr v-if="orderDetails.delivery_date" class="mb-1">
                      <td class="py-0 pl-0 font-weight-light" style="width: 20%;color: #58595B">Date :</td>
                      <td class="py-0 pl-0" style="width: 80%"><span class="font-weight-light" style="color: #58595B">{{
                          orderDetails.delivery_date
                        }}</span>
                      </td>
                    </tr>

                    <!--                      <tr v-if="orderDetails.user.firstName && orderDetails.user.lastName"
                                              class="mb-1">
                                            <td class="py-0 pl-0" style="width: 20%">Served by :</td>
                                            <td class="py-0 pl-0" style="width: 80%"><span class="font-weight-light" style="color: #58595B">{{
                                                `${orderDetails.user.firstName} ${orderDetails.user.lastName}`
                                              }}</span>
                                            </td>
                                          </tr>-->

                    </tbody>
                  </table>

                </div>
                <div
                    class="col-sm-4 col-12 text-center text-sm-right order-1 order-sm-2 d-sm-flex justify-content-end mb-1 mb-sm-0">
                  <!-- <img src="../../../app-assets/images/pages/pixinvent-logo.png" alt="logo" height="46" width="164"> -->
                </div>
              </div>
              <div class="row py-1">
                <div class="col-12">
                  <hr>
                </div>
              </div>

              <!-- invoice address and contact -->
              <div class="row invoice-info">
                <div class="col-sm-4 col-12">
                  <h6 class="font-weight-bold">Customer Details</h6>
                  <div v-if="orderDetails?.user">
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Name : </span>
                      <span
                          style="color:#404041;font-weight:400;">{{
                          `${orderDetails?.user?.first_name ?? ''} ${orderDetails?.user?.last_name ?? ''}`
                        }}</span>
                    </div>
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Address : </span>
                      <span
                          style="color:#404041;font-weight:400;">{{
                          fullAddress(orderDetails?.user?.customer)
                        }}</span>
                    </div>
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Email : </span>
                      <span style="color:#404041;font-weight:400;">{{ orderDetails?.user?.email }}</span>
                    </div>
                  </div>


                </div>
                <div class="col-sm-4 col-12 mt-1">
                  <h6 class="font-weight-bold">Billing Address</h6>
                  <div class="mb-1" v-if="orderDetails?.billingAddress?.id">
                      <span>{{
                          `${orderDetails.billingAddress.street},
                        ${orderDetails.billingAddress.suburb},
                        ${orderDetails.billingAddress.state},
                        ${orderDetails.billingAddress.post_code},
                        ${orderDetails.billingAddress.country}`
                        }}
                      </span>
                  </div>
                </div>

                <div class="col-sm-4 col-12 mt-1">
                  <h6 class="font-weight-bold">Shipping Address</h6>
                  <div class="mb-1" v-if="orderDetails?.shippingAddress?.id">
                      <span>{{
                          `${orderDetails.shippingAddress.street},
                        ${orderDetails.shippingAddress.suburb},
                        ${orderDetails.shippingAddress.state},
                        ${orderDetails.shippingAddress.post_code},
                        ${orderDetails.shippingAddress.country}`
                        }}
                      </span>
                  </div>

                </div>
              </div>
              <hr>

            </div>

            <div class="">
              <div class="">
                <div class="">
                  <h3 class="text-center font-weight-bold text-primary" v-if="orderDetails?.delivery_date">
                    Delivery: {{
                      new Date(`${orderDetails.delivery_date}`).toLocaleDateString("en-AU", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                    }}</h3>
                </div>
              </div>
            </div>

            <!-- product details table-->
            <div>
              <div class="d-md-flex justify-content-md-between mb-2">
                <div class="mb-2 mb-md-0" @click="showProductList">
                  <button class="btn btn-primary btn-sm "> Add Product</button>
                </div>
                <div class="">
                  <button class="btn btn-danger btn-sm mb-2 mb-md-0" data-toggle="modal" data-target="#addAppliedDiscount"> Add
                    Discount
                  </button>
                  <button class="btn btn-warning btn-sm ml-md-1 d-block d-md-inline-block" data-toggle="modal" data-target="#updateDeliveryCharge">
                    Add
                    Delivery Charge
                  </button>
                </div>

              </div>

              <div>
                <div class="invoice-product-details table-responsive ">
                  <table class="table table-borderless mb-0">
                    <thead>
                    <tr class="border-0">
                      <th class="text-white bg-primary" style="width: 25%;border-right:1px solid white;">
                        PRODUCT
                      </th>
                      <th class="text-center text-white bg-primary"
                          style="width: 25%;border-right:1px solid white;">QTY
                      </th>
                      <th class="text-center text-white bg-primary"
                          style="width: 25%;border-right:1px solid white;">UNIT PRICE
                      </th>
                      <th class="text-right text-white bg-primary"
                          style="width: 25%;border-right:1px solid white;">PRICE
                      </th>
                    </tr>
                    </thead>

                    <tbody v-if="cartList.length > 0">
                    <tr v-for="(cartItem,index) in cartList" :key="index">
                      <td class="text-capitalize" style="color:#000;font-weight:500;">
                        {{ cartItem?.name }} ({{ cartItem?.condition_name }})
                        <span class="ml-1">
                            <button type="button" class="btn btn-sm px-0 py-0" style="padding:0;"
                                    @click="getSerial(index, cartItem)">
                              <i class='bx bx-edit-alt bx-tada text-warning'></i>
                            </button>
                          </span>
                        <div v-if="cartItem?.orderProductItems && cartItem.orderProductItems.length > 0">
                          <ul>
                            <li v-for="(singleItem) in cartItem?.orderProductItems" :key="singleItem.id">
                              {{ singleItem.productItem.serial_number }}
                              <span
                                  v-if="singleItem.productItem?.productLicense">{{
                                  singleItem.productItem?.productLicense?.license_key
                                }}
                                  </span>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td class="text-center" style="color:#000;font-weight:500;">
                        {{ cartItem?.quantity }}
                      </td>
                      <td class="text-center" style="color:#000;font-weight:500;">
                        ${{ parseFloat(cartItem?.price / 100).toFixed(2) }}
                      </td>
                      <td class="text-right" style="color:#000;font-weight:500;">
                        ${{ (parseFloat(cartItem?.price / 100) * cartItem?.quantity).toFixed(2) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!-- invoice subtotal -->
                <div class="pb-2 pt-0">
                  <hr>
                  <table class="table table-borderless ">
                    <tbody>
                    <!-- subtotal -->
                    <tr class="mb-1">
                      <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">SubTotal :</td>
                      <td class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(subTotal / 100).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <!-- charges -->
                    <tr class="mb-1" v-for="(charge,index) in orderPayment.chargesDetails" :key="index">
                      <td v-if="index !== 0" class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">
                        <span class="text-capitalize">{{ charge.name }} ({{ charge.sign }}) : </span>
                      </td>
                      <td v-if="index !== 0" class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(charge.amount / 100).toFixed(2) }}
                            </span>
                      </td>
                    </tr>


                    <tr class="mb-1">
                      <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Grand Total :</td>
                      <td class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(orderPayment.total / 100).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr class="mb-1">
                      <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Paid :</td>
                      <td class="py-0 pl-4 pr-0" style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(orderPayment.paid / 100).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr class="mb-1" v-if="orderPayment.remaining > 0">
                      <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Due :</td>
                      <td class="py-0 pl-4 pr-0" style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(orderPayment.remaining / 100).toFixed(2) }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- invoice action  -->
        </div>
      </div>
      <update-product-serial-modal :new-product-items="productItems"
                                   :added-product-items="addedProductItems"
                                   :selected-product="selectedProduct"
                                   :selected-state="selectedState"
                                   :selected-condition="selectedCondition"
                                   :cart-item-quantity="selectedQuantity"
                                   @addProductItem="onAddProductItem"
                                   @deleteProductItem="onDeleteProductItem">
      </update-product-serial-modal>
      <ProductListModal :products="products" @selectedProductCondition="onSelectedProductCondition"/>
      <div class="" data-toggle="modal" data-target="#productListModal"></div>
      <div class="" data-toggle="modal" data-target="#updateProductSerialModal"></div>
      <update-delivery-charge-modal :modal-usages-for="2" modal-name="updateDeliveryCharge"
                                    :delivery-charge-amount="orderDeliveryChargeAmount"
                                    @getDeliveryCharge="onGetDeliveryCharge">
      </update-delivery-charge-modal>
      <add-applied-discount-modal :modal-usages-for="1" modal-name="addAppliedDiscount"
                                  @getDiscount="onGetDiscount"></add-applied-discount-modal>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import PrintElement from "@/components/backEnd/mixins/PrintElement";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import UpdateProductSerialModal from "@/views/backEnd/Orders/includes/UpdateProductSerialModal";
import ProductListModal from "@/views/backEnd/Orders/includes/ProductListModal";
import UpdateDeliveryChargeModal from "@/views/backEnd/Orders/includes/UpdateDeliveryChargeModal";
import AddAppliedDiscountModal from "@/views/backEnd/Orders/includes/UpdateDeliveryChargeModal";


export default {
  name: "OrderUpdateAfterProcess",
  mixins: [ShowToastMessage, Loader, PrintElement],

  components: {
    AppLayout,
    UpdateProductSerialModal,
    ProductListModal,
    UpdateDeliveryChargeModal,
    AddAppliedDiscountModal
  },
  data() {
    return {
      getOrderParams: {
        with_relation: [
          'user',
          'user.customer.address',
          'user.customer.address.business',
          'orderCharges',
          'orderPayments.payment',
          'orderPaymentsByReference',
          'appointment.technicianAppointment.technician.user',
          'shippingAddress.business',
          'billingAddress.business',
          'orderNotes',
          'orderProductItems.productItem.product',
          'orderProductItems.productItem.productLicense',
          'orderProductItems.productItemTransactions',
          'orderRefundsByReference',
        ],
      },
      orderPayment: {
        total: '',
        paid: '',
        remaining: '',
        chargesDetails: '',
      },
      subTotal: 0,
      cartList: [],
      getSettingsParams: {
        type: ['product_item', 'default'],
        key: ['product_item_condition', 'default_gst_rate'],
      },
      selectedProduct: {},
      selectedCondition: {},
      selectedState: '',
      selectedQuantity: null,
      addedProductItems: [],
      selectedIndex: '',
      orderItemPriceObj: {},
      orderGSTChargeObj: {},

      getProductsParams: {
        with_relation: ['addedBy', 'productCategory', 'productItems', 'media'],
        where_has_product_price_state_and_status: []

      },
      products: {},
      orderDeliveryChargeAmount: '',
    }
  },

  computed: {
    ...mapGetters({
      authUser: 'appAuthLogin/authUser',
      orderDetails: 'appOrders/order',
      productItems: 'appProductItems/productItems',
      orderProductItem: 'appOrderProductItems/orderProductItem',
      productItemTransaction: 'appProductItemTransaction/productItemTransaction',
      productItemTracking: 'appProductItemTracking/productItemTracking',
    }),
    conditionLists() {
      return this.$store.getters['appSettings/settingProductItemCondition'].value.map((item) => {
        let value = item.value;
        let name = item.name;
        return {value: value, name: name};
      });
    },
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getOrder: 'appOrders/getOrder',
      getProducts: 'appProducts/getProducts',
      getProductItems: 'appProductItems/getProductItems',

      putOrderProductItemAdd: 'appOrders/putOrderProductItemAdd',
      putOrderProductItemRemove: 'appOrders/putOrderProductItemRemove',
      putOrderDeliveryCharge: 'appOrders/putOrderDeliveryCharge',
      postOrderDiscountAdd: 'appOrders/postOrderDiscountAdd',

    }),
    async onGetDeliveryCharge(data) {
      let status = await this.updateOrderDeliveryCharge(data);
      if (status) {
        this.getOrderCalculation(this.orderDetails)
        document.querySelector('[data-target="#updateDeliveryCharge"]').click();
      }
    },
    async onGetDiscount(data) {
      let status = await this.addOrderDiscount(data);
      if (status) {
        this.getOrderCalculation(this.orderDetails)
        document.querySelector('[data-target="#addAppliedDiscount"]').click();
      }
    },
    async addOrderDiscount(data) {
      this.loader(true)
      const dataObj = {
        id: this.orderDetails.id,
        data: {...data, amount: data.amount * 100}
      };
      return await this.postOrderDiscountAdd(dataObj).then((response) => {
        this.loader(false)
        return response.status === 200 || response.status === 201;
      });
    },
    async updateOrderDeliveryCharge(data) {
      this.loader(true)
      const dataObj = {
        id: this.orderDetails.id,
        data: {amount: data * 100}
      };
      return await this.putOrderDeliveryCharge(dataObj).then((response) => {
        this.loader(false)
        return response.status === 200 || response.status === 201;
      });
    },

    onSelectedProductCondition(data) {
      const {productId, productPriceId, amount} = data
      let selectedProductWithPrices = this.products.find((product) => product.id === productId);
      let selectedCondition = selectedProductWithPrices.productPrices.find((productPrice) => productPrice.id === productPriceId)?.condition ?? null

      //find product with condition name exist on cart list
      let productPriceIndex = this.cartList.findIndex((singleItem) => singleItem.id === productId && singleItem.condition_name === selectedCondition)
      if (productPriceIndex === -1) {
        let cartObj = {
          id: selectedProductWithPrices.id,
          name: selectedProductWithPrices.name,
          condition_name: selectedCondition,
          condition_value: this.conditionLists.find(({name}) => name.toLowerCase() === selectedCondition.toLowerCase())?.value ?? null,
          quantity: 0,
          imageUrl: selectedProductWithPrices?.images?.[0],
          price: amount * 100,
          orderProductItems: []
        }
        this.cartList.push(cartObj);
        let index = this.cartList.length - 1;
        this.getSerial(index, cartObj)
        this.showToastMessage({
          type: 'success',
          message: `successfully ${cartObj.name} with ${cartObj.condition_name} type added in cart`
        });
      } else {
        let existProductInfoOnList = this.cartList[productPriceIndex];
        this.showToastMessage({
          type: 'warning',
          message: `Already ${existProductInfoOnList.name} with ${existProductInfoOnList.condition_name} type added in cart`
        });
      }
    },
    async getProductList(paramObj) {
      await this.getProducts(paramObj)
          .then((response) => {
            this.isLoading = !!(response && response.message && response.status !== 200);
          });
    },
    async showProductList() {
      this.getProductsParams.with_product_prices_state = this.orderDetails.billingAddress.state.toLowerCase() ?? '';
      this.getProductsParams.where_has_product_price_state_and_status[0] = this.orderDetails.billingAddress.state.toLowerCase() ?? '';
      this.getProductsParams.where_has_product_price_state_and_status[1] = 1;
      await this.getProductList(this.getProductsParams);
      this.products = this.$store.getters['appProducts/products'];
      document.querySelector('[data-target="#productListModal"]').click();
    },

    async deleteProcessOrderProductItem(productItemId) {
      this.loader(true)
      const dataObj = {
        id: this.orderDetails.id,
        data:
            {
              user_id: this.orderDetails.user.id,
              product_item_id: productItemId
            }
      };

      return await this.putOrderProductItemRemove(dataObj).then((response) => {
        this.loader(false)
        return response.status === 200 || response.status === 201;
      });
    },


    async addProcessOrderProductItem(productItemId, sellPrice) {
      this.loader(true)
      const dataObj = {
        id: this.orderDetails.id,
        data:
            {
              user_id: this.orderDetails.user.id,
              product_item_id: productItemId,
              sell_price: sellPrice
            }
      };

      return await this.putOrderProductItemAdd(dataObj).then((response) => {
        this.loader(false)
        return response.status === 200 || response.status === 201;
      });
    },


    async onAddProductItem(productItemId) {
      console.log('ADD PRODUCT ORDER ITEM ');
      this.loader(true)
      const selectedProductItem = this.productItems.find(({id}) => id === productItemId)
      const productItemPrice = this.cartList[this.selectedIndex].price;
      this.subTotal = this.subTotal + productItemPrice
      let productLicense = null
      if (selectedProductItem.productLicense !== null) {
        productLicense = selectedProductItem.productLicense
      }

      await this.addProcessOrderProductItem(productItemId, productItemPrice);
      console.log('ADD PROCESS ORDER ITEM ');
      const itemObj = {
        order_product_item_id: this.orderProductItem.id,
        product_item_transaction_id: this.productItemTransaction.id,
        product_license_id: productLicense?.id ?? null,
        productItem: selectedProductItem
      }
      
      // this.cartList[this.selectedIndex].quantity++;
     

      this.$store.dispatch('appProductItems/commitDeleteProductItemOnList', productItemId)
      this.cartList[this.selectedIndex].orderProductItems.push(itemObj);
      this.cartList[this.selectedIndex].quantity = this.cartList[this.selectedIndex]?.orderProductItems?.length ?? 0; 
      this.addedProductItems.push(itemObj)
      this.getOrderCalculation(this.orderDetails)
      this.loader(false)
    },

    async onDeleteProductItem(productItemId) {
      this.loader(true)
      const {
        productItem
      } = this.cartList[this.selectedIndex].orderProductItems.find((item) => item.productItem.id === productItemId);

      const product_item_id = productItem.id;
      // delete order  product item, product item transaction, product license
      await this.deleteProcessOrderProductItem(product_item_id)
      // select deleted item price from  cartList array
      const productItemPrice = this.cartList[this.selectedIndex].price;
      this.subTotal = this.subTotal - productItemPrice
      this.cartList[this.selectedIndex].quantity--

      this.cartList[this.selectedIndex].orderProductItems = this.cartList[this.selectedIndex].orderProductItems.filter((item) => item.productItem.id !== productItemId)
      this.addedProductItems = this.addedProductItems.filter((item) => item.productItem.id !== productItemId)
      this.$store.dispatch('appProductItems/commitCreateProductItemOnList', {data: productItem})

      if (this.cartList[this.selectedIndex].orderProductItems.length === 0) {
        this.cartList.splice(this.selectedIndex, 1);
        document.querySelector('[data-target="#updateProductSerialModal"]').click();
      }
      this.getOrderCalculation(this.orderDetails)
      this.loader(false)
    },

    fullAddress(customerObj) {
      let businessName = customerObj?.address?.business?.name ?? '';
      let addressStreet = customerObj?.address?.street;
      let addressSuburb = customerObj?.address?.suburb;
      let addressState = customerObj?.address?.state.toUpperCase();
      let addressPostCode = customerObj?.address?.post_code;
      let addressCountry = customerObj?.address?.country;
      return customerObj?.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    async getSingleOrderDetails(id) {
      let paramObj = {
        id: id,
        params: this.getOrderParams,
      };
      await this.getOrder(paramObj).then(async (response) => {
        this.loader(false);

        this.cartList = this.generateCartListFromOrderProductData(this.orderDetails.orderProductItems)
        this.getOrderCalculation(this.orderDetails)

        this.subTotal = this.cartList.reduce(function (acc, cur) {
          return acc + (cur.price * cur.quantity)
        }, 0);

        this.orderDeliveryChargeAmount = this.orderDetails.orderCharges.find(({type}) => type === 'Delivery Charge')?.amount ?? 0

        if (response && response.status === 404) {
          await this.$router.push({name: '404'});
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getProductItemList(productId, productConditionType) {
      const paramObj = {
        product_id: productId,
        condition: productConditionType,
        state: this.orderDetails.billingAddress.state,
        where_doesnt_have_product_item_transaction: 1,
        with_relation: ['productLicense', 'product']
      }
      await this.getProductItems(paramObj).then(async (response) => {
        this.loader(false);

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getSerial(index, cartItem) {
      // pass to modal component
      this.selectedIndex = index;
      this.selectedProduct = {id: cartItem.id, name: cartItem.name};
      this.selectedCondition = {value: cartItem.condition_value, name: cartItem.condition_name}
      this.selectedState = this.orderDetails.billingAddress.state;
      this.selectedQuantity = cartItem.quantity;
      this.addedProductItems = JSON.parse(JSON.stringify(this.cartList[index]?.orderProductItems ?? []));

      await this.getProductItemList(cartItem.id, cartItem.condition_value);
      document.querySelector('[data-target="#updateProductSerialModal"]').click();
    },

    getOrderCalculation(order) {
      let orderCharge = this.calculateOrderCharges(order);
      let orderPayment = this.calculateOrderPayments(order);
      let orderChargesDetails = this.getOrderChargesDetails(order);

      let remaining = orderCharge - orderPayment;
      this.orderPayment = {
        total: orderCharge,
        paid: orderPayment,
        remaining: remaining,
        chargesDetails: orderChargesDetails
      };

    },

    calculateOrderCharges(singleOrder) {
      const decreaseType = ['Discount', 'Coupon']
      if (singleOrder.status === 'Pending') {
        return singleOrder?.details?.grand_total ?? 0;
      } else {
        return singleOrder.orderCharges.reduce(function (accu, cur) {
          accu = (decreaseType.includes(cur.type)) ? accu - cur.amount : accu + cur.amount
          return accu
        }, 0);
      }

    },

    calculateOrderPayments(singleOrder) {
      if (singleOrder.status === 'Pending') {
        return singleOrder.orderPaymentsByReference.reduce(function (acc, cur) {
          return acc + cur.total_transaction
        }, 0);
      } else {
        return singleOrder.orderPayments.reduce(function (accu, cur) {
          return (!('payment' in cur) && (!cur.payment)) ? 0 : accu + cur.payment.total_transaction
        }, 0);
      }

    },

    getOrderChargesDetails(singleOrder) {

      let orderItemsPrice = 0, orderGST = 0, orderDiscount = 0, orderSurCharge = 0, orderDeliveryCharge = 0,
          discountDetails = [];
      let appliedDiscount = 0, couponDiscount = 0, creditedPaymentDiscount = 0;


      if (singleOrder.status === 'Pending') {
        orderGST = singleOrder.details['gst_charge'].applied_status === true ? singleOrder.details['gst_charge'].amount : 0;
        orderDeliveryCharge = singleOrder.details['order_delivery_charge'].applied_status === true ? singleOrder.details['order_delivery_charge'].amount : 0;
        appliedDiscount = singleOrder.details['applied_discount'].applied_status === true ? singleOrder.details['applied_discount'].amount : 0;
        couponDiscount = singleOrder.details['coupon_discount'].applied_status === true ? singleOrder.details['coupon_discount'].amount : 0;
        creditedPaymentDiscount = singleOrder.details['credited_payment_discount'].applied_status === true ? singleOrder.details['credited_payment_discount'].amount : 0;
        orderDiscount = appliedDiscount + couponDiscount + creditedPaymentDiscount;
        orderItemsPrice = singleOrder.details['subtotal_amount'] ?? 0;
        orderSurCharge = singleOrder.details['surcharge'] ?? 0
        discountDetails = [
          {name: 'applied discount', amount: appliedDiscount},
          {name: 'coupon discount', amount: couponDiscount},
          {name: 'credited payment discount', amount: creditedPaymentDiscount},
        ]
      } else {
        singleOrder.orderCharges.forEach((charge) => {
          if (charge.type === 'GST') {
            orderGST = orderGST + charge?.amount ?? 0
          }
          if (charge.type === 'Surcharge') {
            orderSurCharge = orderSurCharge + charge?.amount ?? 0
          }
          if (charge.type === 'Items Price') {
            orderItemsPrice = orderItemsPrice + charge?.amount ?? 0
          }
          if (charge.type === 'Delivery Charge') {
            orderDeliveryCharge = orderDeliveryCharge + charge?.amount ?? 0
          }
          if (charge.type === 'Discount') {
            orderDiscount = orderDiscount + charge?.amount ?? 0
            discountDetails.unshift({name: charge.name, amount: charge.amount})
          }
        });
      }

      return [
        {name: 'items price', sign: '+', amount: orderItemsPrice},
        {name: 'surcharge', sign: '+', amount: orderSurCharge},
        {
          name: 'discount', sign: '-', amount: orderDiscount,
          details: discountDetails
        },
        {name: 'GST', sign: '+', amount: orderGST},
        {name: 'delivery charge', sign: '+', amount: orderDeliveryCharge},
      ]

    },

    generateCartListFromOrderProductData(actualOrderItemList) {
      let cartProducts = {};

      actualOrderItemList.map((orderItem) => {
        if (cartProducts[`${orderItem.productItem.product.id}_${orderItem.productItem.condition}`] !== undefined) {
          cartProducts[`${orderItem.productItem.product.id}_${orderItem.productItem.condition}`].quantity++;
          cartProducts[`${orderItem.productItem.product.id}_${orderItem.productItem.condition}`].orderProductItems.push({
            order_product_item_id: orderItem.id,
            product_item_transaction_id: orderItem?.productItemTransactions?.id,
            product_license_id: orderItem?.productItem?.productLicense?.id ?? null,
            productItem: orderItem.productItem,
          });
          return;
        }

        cartProducts[`${orderItem.productItem.product.id}_${orderItem.productItem.condition}`] = {
          id: orderItem.productItem.product.id,
          name: orderItem.productItem.product.name,
          condition_name: orderItem.productItem.condition,
          condition_value: this.conditionLists.find(({name}) => name.toLowerCase() === orderItem.productItem.condition.toLowerCase())?.value ?? null,
          quantity: 1,
          imageUrl: orderItem.productItem.product.images[0],
          price: orderItem?.productItemTransactions?.sell_price ?? 0,
          orderProductItems: [
            {
              order_product_item_id: orderItem.id,
              product_item_transaction_id: orderItem?.productItemTransactions?.id,
              product_license_id: orderItem?.productItem?.productLicense?.id ?? null,
              productItem: orderItem.productItem,
            }
          ],
        }
        // end map function
      });
      return Object.values(cartProducts);

    },


  },
  async mounted() {
    this.loader(true);
    await this.getSettingList(this.getSettingsParams);
    const routeParams = await this.$route.params;
    await this.getSingleOrderDetails(routeParams.id);
    this.loader(false);
  },
}
</script>

<style scoped>

</style>