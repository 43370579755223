<template>
  <div class="modal fade text-left" id="alertModal" tabindex="-1" role="dialog" :aria-labelledby="alertModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-danger">
          <h5 class="modal-title white" id="">Caution on Deletion</h5>
          <button type="button" class="close" @click="confirmModelDeletion(false)" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center mx-2">
            <i style="font-size: 4rem;" class='bx bx-error-circle bx-flashing text-danger mx-2'></i>
            <br>
            <span>Do you really want to delete this records? <br> This process can't be undone.</span>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-light-secondary" @click="confirmModelDeletion(false)">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
          <button type="button" class="btn btn-sm btn-danger ml-1" @click="confirmModelDeletion(true)">
            <i class="bx bx-check d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Delete</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppointmentNoteDeleteAlertModal',
  emits: ['confirmModelDeletion'],
  props: [],
  methods: {
    confirmModelDeletion(status) {
      document.querySelector('[data-target="#alertModal"]').click();
      this.$emit('confirmModelDeletion', status);
    },

  }
}
</script>

<style>

</style>