<template>
  <div class="modal fade" id="updateProductSerialModal" tabindex="-1" role="dialog"
       aria-labelledby="updateProductSerialModal"
       aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white text-capitalize">{{ selectedProduct.name }}({{ selectedCondition.name }})</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetModal()">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">

          <div class="table-sm table-responsive">
            <table id="users-list-datatable" class="table">
              <thead>
              <tr>
                <th class="position-relative" :style="{width:'20%'}">
                  SERIAL

                </th>
                <th class="position-relative" :style="{width:'18%'}">
                  MODEL


                </th>
                <th class="position-relative" :style="{width:'12%'}">
                  SKU


                </th>
                <th class="position-relative" :style="{width:'20%'}">
                  BAR CODE

                </th>
                <th class="position-relative" :style="{width:'15%'}">
                  QR CODE

                </th>
                <!--                <th class="position-relative" :style="{width:'10%'}">
                                  M. CODE
                                  <div class="position-absolute sorting-up-button">
                                    <i class='bx bx-chevron-up'></i>
                                  </div>
                                  <div class="position-absolute sorting-down-button">
                                    <i class='bx bx-chevron-down'></i>
                                  </div>
                                </th>-->
                <th :style="{width:'5%'}">
                  ACTIONS
                </th>
              </tr>
              </thead>
              <!--          already added product item-->
              <tbody>
              <tr v-for="(item, index) in addedProductItems" :key="index">
                <td>{{ item.productItem.serial_number }}</td>
                <td>{{ item.productItem.model_number }}</td>
                <td>{{ item.productItem.sku }}</td>
                <td>{{ item.productItem.qr_code }}</td>
                <td>{{ item.productItem.barcode }}</td>
                <td>
                  <button @click="deleteAddedProductItem(item.productItem.id)"
                          class="bg-transparent text-decoration-none cursor-pointer border-0 view-edit-delte-icon"
                          :style="{height:'18px'}">
                    <i class='bx bx-x text-danger'></i>
                  </button>
                </td>
              </tr>
              </tbody>

              <!--          new product item-->

            </table>


          </div>


          <h5 class="text-center bg-dark text-white py-1"> New Items</h5>

          <table>
            <thead>
            <tr>
              <th class="position-relative" :style="{width:'20%'}">
                SERIAL

              </th>
              <th class="position-relative" :style="{width:'18%'}">
                MODEL


              </th>
              <th class="position-relative" :style="{width:'12%'}">
                SKU


              </th>
              <th class="position-relative" :style="{width:'20%'}">
                BAR CODE

              </th>
              <th class="position-relative" :style="{width:'15%'}">
                QR CODE

              </th>
              <!--                <th class="position-relative" :style="{width:'10%'}">
                                M. CODE
                                <div class="position-absolute sorting-up-button">
                                  <i class='bx bx-chevron-up'></i>
                                </div>
                                <div class="position-absolute sorting-down-button">
                                  <i class='bx bx-chevron-down'></i>
                                </div>
                              </th>-->
              <th :style="{width:'5%'}">
                ACTIONS
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(productItem, index) in productItemsData" :key="index">
              <td>{{ productItem.serial_number }}</td>
              <td>{{ productItem.model_number }}</td>
              <td>{{ productItem.sku }}</td>
              <td>{{ productItem.qr_code }}</td>
              <td>{{ productItem.barcode }}</td>
              <!--                <td>{{ productItem.manufacturing_code }}</td>-->
              <td>
                <button @click="addProductItem(productItem.id)"
                        class="bg-transparent text-decoration-none cursor-pointer border-0 view-edit-delte-icon"
                        :style="{height:'18px'}">
                  <i class='bx bx-plus text-success'></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <hr>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button v-if="isProductModalShow" type="button" class="btn btn-success" data-toggle="modal"
                  data-target="#productItemAddModal">
            <span class="">Add Product Item</span>
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">

            <span class="" @click="resetModal()">Close</span>
          </button>
          <!--          <button type="button" class="btn btn-primary" @click="addItemSerial">
                      <i class="bx bx-x d-block d-sm-none"></i>
                      <span class="d-none d-sm-block">Add Serial</span>
                    </button>-->
        </div>
      </div>
    </div>
  </div>
  <alert-modal @confirmModelDeletion="onConfirmModelDeletion"></alert-modal>
  <div class="" data-toggle="modal" data-target="#alertModal"></div>
  <AddProductItemModal @getCreatedData="createdData" :product-id="selectedProduct.id"
                       :state-id="selectedState" :condition-id="selectedCondition.value"/>
</template>

<script>
import AddProductItemModal from "@/views/backEnd/Orders/includes/AddProductItemModal";
import AlertModal from "@/components/backEnd/modal/AlertModal";

export default {
  name: "UpdateProductSerialModal",
  components: {
    AddProductItemModal,
    AlertModal

  },
  props: ['newProductItems', 'addedProductItems', 'selectedProduct', 'selectedState', 'selectedCondition', 'cartItemQuantity'],
  emits: ['addProductItem', 'deleteProductItem'],
  data() {
    return {
      checkedItem: [],
      isProductModalShow: false,
      productItemsData: [],
      status: '',
      deleteId: ''
    }
  },
  watch: {
    newProductItems(productItems) {
      this.productItemsData = productItems;
      this.productItemsCount();
    }
  },
  computed: {},
  methods: {
    addProductItem(productItemId) {
      this.$emit('addProductItem', productItemId)
    },
    deleteAddedProductItem(productItemId) {
      this.deleteId = productItemId
      document.querySelector('[data-target="#alertModal"]').click();
    },
    onConfirmModelDeletion(status) {
      if (status) {
        this.$emit('deleteProductItem', this.deleteId)
      }
    },
    addItemSerial() {
      this.$emit('addProductItem', this.checkedItem)
    },
    createdData(data) {
      this.productItemsData.unshift(data);
      this.$emit('addProductItem', data.id)
      this.productItemsCount();
    },
    async productItemsCount() {
      let availableItem = this.newProductItems.reduce((counter) => counter += 1, 0);
      this.isProductModalShow = this.cartItemQuantity == 0 ? true :   availableItem < this.cartItemQuantity;
    },
    resetModal() {
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  }

}
</script>

<style>

</style>
